<template>
  <component :is="commonComponent" id="terms" title="Terms of services">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-row">
        Welcome to {{ domainConfig['IAmURL'] }}. By accessing or using our website, you agree to
        comply with and
        be bound by the following terms and conditions:
      </div>

      <div class="common-content-row">
        <ol>
          <li>
            <strong>Use of Website</strong>: You agree to use this site for lawful purposes only.
            You must not use it in any way that may cause damage to the site or impair the
            availability or accessibility of the site.
          </li>
          <li>
            <strong>Intellectual Property</strong>: All content on this website is owned by or
            licensed to {{ domainConfig['IAmURL'] }}. You may not reproduce, distribute, or create
            derivative
            works from any content without our express written permission.
          </li>
          <li>
            <strong>Account Security</strong>: If you create an account, you are responsible for
            maintaining the confidentiality of your login information and for all activities under
            your account.
          </li>
          <li>
            <strong>Changes to Terms</strong>: We may update these terms from time to time.
            Continued use of the site will be deemed acceptance of any changes.
          </li>
          <li>
            <strong>Contact Us</strong>: For any questions, please contact us at <a
              class="footer-item-content-item"
              :href="`mailto:${domainConfig['IAMEMAIL']}`">{{ domainConfig['IAMEMAIL'] }}</a>.
          </li>
        </ol>
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Terms of services | ${this.domainConfig["IAmURL"]}`,
      meta: [
        {
          name: 'description',
          content: `Access the Bible anytime with ${this.domainConfig['IAmURL']}. Enjoy seamless navigation for study and reflection.`,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>